
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import VTable from '@/components/shared/table/VTable.vue';
import { Table } from '@/interfaces/models/Table';
import tagHeaders from './headers';
import { Tag } from '@/interfaces/models/Tag';
import { Permission } from '@/enums/Permission';

@Component({
  components: { VTable },
})
export default class TagTable extends Vue {
  @Prop({ type: Array, required: true }) public items!: Tag[];
  @Prop({ type: Number, required: true }) public total!: number;

  public headers: ColumnHeader[] = this.accessibleHeaders;

  get accessibleHeaders() {
    return tagHeaders.filter((c: ColumnHeader) => {
      if (c.value === 'isRecommendationTag' && !this.$can(Permission.ADMIN)) {
        return false;
      }
      return true;
    });
  }

  public edit(item: Table) {
    this.$router.push({ name: 'tag.edit', params: { id: item._id }, query: { ...this.$route.query } });
  }
}
