
import { Component, Vue, Watch } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import VEmptyState from '@/components/shared/VEmptyState.vue';
import TagTable from '@/components/tag/TagTable.vue';
import Filter from '@/interfaces/api/Filter';
import { Tag as TagModel } from '@/interfaces/models/Tag';

const venue = namespace('venue');
const tag = namespace('tag');

@Component({
  components: { TagTable, VEmptyState, VWrapper },
})
export default class Tag extends Vue {
  @venue.State('active') public venue!: Venue;

  @tag.Action('setFilter') public setFilter!: (filter: Filter) => void;
  @tag.Action('fetch') public getTags!: any;
  @tag.Action('destroy') public deleteTag!: (u: { id: string }) => {};
  @tag.State((state) => state.pagination.total) public total!: number;

  @tag.State('items') public items!: Tag[];

  public created() {
    if (!this.venue) {
      this.$startLoading('venue');
    }
  }

  public async mounted() {
    if (!this.venue) {
      this.$startLoading('venue');
    }
    if (this.venue) {
      this.$startLoading('table');
      this.setFilter({ venue: this.venue._id });
      await this.getTags({ page: 1 });
      this.$stopLoading('table');
    }
  }

  public async destroy(tag: TagModel) {
    this.$startLoading('tag');
    await this.deleteTag({ id: tag._id });
    this.$stopLoading('tag');
  }
  @Watch('venue')
  public onVenueChange() {
    if (this.venue && this.venue._id) {
      if (this.venue) {
        this.$startLoading('constrain');
        this.setFilter({ venue: this.venue._id });
        this.getTags({ page: 1 });
        this.$stopLoading('constrain');
      }
    }
  }
}
